import React, {useState, useEffect} from "react"
import Img from "gatsby-image"
import {motion} from "framer-motion"
import {useSwipeable} from "react-swipeable"
import constants from "../../constants";
import ArrowIcon from "../arrow-icon";
import ModalStore from "../../store/modal";
import {Play, Smile} from "../icons";

const ImageSliderEx = ({
                           videos,
                           videosThumb,
                           heading,
                           tinyHeading,
                           paragraph,
                           arrowBgColor,
                           arrowColor,
                           bgColor,
                           playColor,
                           info
                       }) => {
    const [position, positionSet] = useState(1);
    const [width, setWidth] = useState(null);

    const handleWidth = () => {
        const imageWidth = document.querySelector("#slideImage").clientWidth;
        setWidth(imageWidth / 16 < 24 ? imageWidth / 16 + 1 : imageWidth / 16 + 3)
    }

    useEffect(() => {
        handleWidth();
        window.addEventListener("resize", handleWidth)
        return () => {
            window.removeEventListener("resize", handleWidth)
        }
    }, [])

    const openModal = (index) => {
        ModalStore.setModalVideo(videos[index], info[index], bgColor, arrowBgColor);
    }

    const handleClick = dir => {
        if (dir === "Right") {
            if (position < videos.length - 1) {
                positionSet(position + 1)
            }
        }
        if (dir === "Left") {
            if (position > 0) {
                positionSet(position - 1)
            }
        }
    }

    const handlers = useSwipeable({
        onSwiped: ({dir}) => {
            if (dir === "Left") {
                if (position < videos.length - 1) {
                    positionSet(position + 1)
                }
            }
            if (dir === "Right") {
                if (position > 0) {
                    positionSet(position - 1)
                }
            }
        },
    })

    return (
        <div className="text-center pt-8 lg:pt-12 pb-16 md:max-w-screen-s lg:pb-10">
            <div
                className={`px-5 pb-6 lg:pb-8 mx-auto ${!tinyHeading ? "lg:max-w-screen-lg" : "lg:max-w-screen-sm"
                } `}
            >
                <h2
                    className={`text-2xl leading-none md:text-4xl lg:text-7xl`}
                    dangerouslySetInnerHTML={{__html: heading}}
                />

                <span className="text-sm block mt-4 md:text-base lg:text-xl">          {paragraph}        </span>
            </div>

            <div
                className={`relative h-70 w-full overflow-hidden flex items-center justify-center md:h-120 xl:h-160 !bg-[${bgColor}]`}
            >
                <div
                    className="h-full w-full flex items-center justify-center"
                    {...handlers}
                >
                    <div className="relative h-60 w-60 md:h-96 md:w-96 xl:h-160 xl:w-160">
                        {videos.map((video, index) => (
                            <motion.div
                                id="slideImage"
                                onLoad={handleWidth}
                                className="absolute overflow-hidden h-full w-full"
                                style={{cursor: 'pointer'}}
                                key={index}
                                initial={{scale: 1, rotation: 0}}
                                animate={{
                                    left: `${(index - position) * width}rem`,
                                }}
                                transition={{
                                    duration: 0.5,
                                    ease: [0.85, 0, 0.15, 1]
                                }}
                                onClick={() => openModal(index)}
                            >
                                <Img
                                    objectFit="cover"
                                    alt=""
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                    fluid={videosThumb[index].childImageSharp.fluid}
                                />

                                <div className={`absolute z-10 left-1/2 w-[220px] h-[220px] lg:h-[360px] lg:w-[360px] top-1/2 transform translate-x-[-55px] lg:translate-x-[-90px] translate-y-[-55px] lg:translate-y-[-90px]`}>
                                  <Play color={playColor} className={`relative z-10`}/>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>

            {/*{`${(position + 1) < 10 ? `${position + 1}` : position + 1}/${videos.length}*/}

            <div className="w-full flex items-center2 justify-center h-8 lg:h-16 pt-4">
                <div style={{width: (width - 3) + 'rem'}} className={`flex flex-row`}>
                    <div className={`w-full lg:w-9/12 text-left lg:pr-4`}>{info[position]}</div>
                    <div className={`w-3/12 hidden lg:block`}>
                        <button style={{outline: 0}} onClick={() => {
                            handleClick("Left")
                        }} className={`mr-4`}>
                            <ArrowIcon className={`transform rotate-180`}/>
                        </button>
                        <button style={{outline: 0}} onClick={() => {
                            handleClick("Right")
                        }}>
                            <ArrowIcon className={``}/>
                        </button>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ImageSliderEx
