import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import Grid from "components/grid"
import {StaticImage} from "gatsby-plugin-image"
import Marquee from "react-fast-marquee"
import constants from "../../constants"
import InlineVideo from "../../components/inline-video"
import {graphql, useStaticQuery} from "gatsby";
import VideoSlider from "../../components/video-slider-ex/VideoSliderEx";
import ImageSliderEx from "../../components/ImageSliderEx";

const AW22Landing = ({location}) => {

    const cssSet = (selector, property, value, important) => {
        try {
            for (let i = 0; i < document.styleSheets.length; i++) {
                const ss = document.styleSheets[i];
                const r = ss.cssRules ? ss.cssRules : ss.rules;

                for (var j = 0; j < r.length; j++) {
                    if (r[j].selectorText && r[j].selectorText === selector) {
                        if (typeof important == 'undefined')
                            r[j].style.setProperty(property, value);
                        else
                            r[j].style.setProperty(property, value, 'important');

                        break;
                    }
                }
            }
        } catch (e) {
            if (e.name !== 'SecurityError') {
                console.log('Developer: ' + e);
            }
        }
    }
    const cssUpdate = (selector, property, value) => {
        let sheets = document.styleSheets;

        for (let sheet of sheets) {
            for (let rule of sheet.cssRules) {
                console.log(rule.selectorText);
                if (rule.selectorText === selector) {
                    //rule.style["content"] = replacementContent;
                }
            }
        }
    }
    // useEffect(() => {
    //     window.locoScroll?.on('scroll', (args) => {
    //
    //         if (typeof args.currentElements['section-01'] === 'object') {
    //             let progress = args.currentElements['section-01'].progress;
    //             let p = Math.floor(30 - 30 * (progress*0.6));
    //             p = p < 0 ? 0 : p;
    //
    //             cssSet('.landing-page #section-011::after', 'top', p + '%', undefined);
    //             //cssUpdate('#section-011::after', 'top', 0);
    //         }
    //     });
    //
    //     return () => {
    //         window.locoScroll?.off('scroll');
    //     };
    // })

    const SectionsMenu = ({className, colors = 'bg-menu-black text-white', options = []}) => {
        const width = `w-1/${options.length}`
        const buttonStyle = `inline lg:inline-flex items-center text-center ${width} lg:w-auto lg:px-6 2xl:px-8 py-3 ${constants.attribution} rounded-full transition duration-300 ease-in-out border border-solid border-transparent whitespace-nowrap`

        return (
            <div className={`${className} flex w-94 lg:w-auto h-12 2xl:h-16 py-1 px-1 ${colors} rounded-full`}>
                {/*
                {options.map(option => {
                    const currentOption = option.id.toUpperCase()
                    return (
                        <button key={option.id} type="button" onClick={() => onClick(constants[currentOption])}
                                className={`${buttonStyle} ${colors} mr-1 ${currentId === constants[currentOption] ? `border-current` : `hover:border-current`}`}>
                            {option.label} Collection
                        </button>
                    )
                })}
*/}
            </div>
        )
    }

    const data = useStaticQuery(graphql`
        fragment fluidImage2 on File { childImageSharp { fluid(maxWidth: 1920, quality:80, ) { ...GatsbyImageSharpFluid_withWebp_noBase64 } } }

        query {
            hero: file(relativePath: { eq: "images/about_image.jpg" }) { ...fluidImage2 }
            thumbnail: file(relativePath: { eq: "images/about/thumbnail.jpg" }) { ...fluidImage2 }
            feature1: file(relativePath: { eq: "images/about/about1.jpg" }) { ...fluidImage2 }
            feature2: file(relativePath: { eq: "images/about/about2.jpg" }) { ...fluidImage2 }
            feature3: file(relativePath: { eq: "images/about/about3.jpg" }) { ...fluidImage2 }
            colab1: file(relativePath: { eq: "images/about/tumblr_a1bd1f92b3b06f4c37b86d9d924523c4_f19aca91_1280-2.jpg" }) { ...fluidImage2 }
            colab2: file(relativePath: { eq: "images/about/Screenshot-2021-01-25-at-18.26-2.jpg" }) { ...fluidImage2 }
            colab3: file(relativePath: { eq: "images/about/ISETANMENSxECCO_keyvisual_78134-2.jpg" }) { ...fluidImage2 }

            slider01: allFile(
                sort: { fields: [name], order: ASC }
                filter: {
                    extension: { regex: "/(jpg)|(jpeg)|(png)/" }
                    relativeDirectory: { eq: "images/aw22/carousel-01" }
                }

            ) { nodes { ...fluidImage2} }
            slider02: allFile(
                sort: { fields: [name], order: ASC }
                filter: {
                    extension: { regex: "/(jpg)|(jpeg)|(png)/" }
                    relativeDirectory: { eq: "images/aw22/carousel-02" }
                }

            ) { nodes { ...fluidImage2} }
            slider03: allFile(
                sort: { fields: [name], order: ASC }
                filter: {
                    extension: { regex: "/(jpg)|(jpeg)|(png)/" }
                    relativeDirectory: { eq: "images/aw22/carousel-03" }
                }

            ) { nodes { ...fluidImage2} }

            pattern01: file(relativePath: { eq: "images/aw22/section-01/pattern_01_extended.png" }) { ...fluidImage }

            slider00thumb01: file(relativePath: { eq: "images/aw22/carousel-00/eric.png" }) { ...fluidImage2 }
            slider00thumb02: file(relativePath: { eq: "images/aw22/carousel-00/stefano.png" }) { ...fluidImage2 }
            slider00thumb03: file(relativePath: { eq: "images/aw22/carousel-00/lauren.png" }) { ...fluidImage2 }
        }
    `)
    const filepath = `../../../static/images/aw22/`;

    const slider01 = data.slider01.nodes;
    const slider02 = data.slider02.nodes;
    const slider03 = data.slider03.nodes;

    const sliderVideos = [
        'https://eccocpeu.blob.core.windows.net/video/elu-aw22/AW2270_B2BLAUNCH_VIDEO_ERIC_A.mp4',
        'https://eccocpeu.blob.core.windows.net/video/elu-aw22/AW2270_B2BLAUNCH_VIDEO_STEFANO_A.mp4',
        'https://eccocpeu.blob.core.windows.net/video/elu-aw22/AW2270_B2BLAUNCH_VIDEO_LAUREN_A.mp4',
    ];
    const sliderVideosThumb = [
        data.slider00thumb01, data.slider00thumb02, data.slider00thumb03
    ];
    const sliderVideosTexts = [
        'Eric Jandar, Lead Designer Accessories',
        `Stefano Pillepich, Head of ECCO Leather Goods`,
        'Lauren McGonnigle, Category Manager',
    ]

    const slider01text = [
        'ECCO Sail Bag Full Size in Vibrant Scarlet',
        'ECCO Textureblock Pot Bag in Vibrant Scarlet',
        'ECCO Wave Pinch Bag in Black',
        'ECCO Wave Pinch Bag in Black',
        'ECCO Textureblock Pinch Bag in Vibrant Scarlet',
        'ECCO Textureblock Pinch Bag in Pastel Lilac/Granita with ECCO Pot Bag Mini In Pastel Lilac',
        'ECCO Textureblock Pinch Bag Mini in Kombu Green',
        'ECCO Sail Bag Compact Siz in Pastel Lilac',
        'ECCO Textureblock Pinch Bag Mini in Pastel Lilac/Granita',
        'ECCO Textureblock Pinch Bag Fill Size in Kombu Green',
    ]
    const slider02text = [
        'ECCO Journey Pillow Pack in Black and Sunny Lime',
        'ECCO Bento Pack in Sunny Lime with ECCO Pot Bag Mini in Berry Sorbet',
        'ECCO Bento Pack in Sunny Lime',
        'ECCO Scrunch Hobo Compact Size in Sunny Lime',
        'ECCO Scrunch Hobo Full Size in First Tee',
        'ECCO Scrunch Hobo Full Size in First Tee and Compact Size in Violet',
        'ECCO Scrunch Hobo Compact Size in Black',
        'ECCO Pot Bag Double in Night Ocean/Cloud',
        'ECCO Indigo Pinch Bag Compact',
        'ECCO Indigo Pinch Bag Compact',
        'ECCO Pot Bag Indigo',
        'ECCO Indigo Double Pot',
    ]
    const slider03text = [
        'ECCO E Phone Stack in First Tee/Berry Sorbet',
        'ECCO E Phone Stack in First Tee/Berry Sorbet',
        'ECCO E Phone Stack in First Tee/Berry Sorbet',
        'ECCO E Phone Stack in Berry Sorbet/Violet',
        'ECCO E Double E Tote In Wet Almond',
        'ECCO E Double E Tote in Black',
        'ECCO E Double E Tote in Black',
        'ECCO E Pot Bag Sling in First Tee',
        'ECCO E Pot Bag Sling in First Tee',
        'ECCO E Pot Bag Sling Monogram in Berry Sorbet/Violet',
    ]

    const TitleH1 = ({title, subtitle}) => {
        return (
            <div className={`px-8`}>
                <div data-scroll data-scroll-speed="1" className={`text-[50px] lg:text-[130px] mb-8 lg:mb-14 mt-2 lg:mt-14 leading-none`} dangerouslySetInnerHTML={{__html: title}}/>
                <div data-scroll data-scroll-speed="2" className={`text-[16px] lg:text-[20px] leading-tight mb-12 nobr`} dangerouslySetInnerHTML={{__html: subtitle}}/>
            </div>
        );
    }

    const TitleH2 = ({title, subtitle, classes}) => {
        return (
            <div className={`${classes} text-left`}>
                <div className={`text-[26px] lg:text-[50px] leading-tight lg:leading-none mb-4 mt-8`} dangerouslySetInnerHTML={{__html: title}}/>
                <div className={`text-[16px] lg:text-[20px] mb-8 nobr`} dangerouslySetInnerHTML={{__html: subtitle}}/>
            </div>
        );
    }

    return (
        <Layout location={location}>
            <SEO title={`AW22 Collection`} pathname={location.pathname}/>

            <div className={`w-full h-full landing-page`} id={`s0`}>

                <section className={'pt-32 lg:pt-44 text-center w-full flex flex-col items-center justify-center'} id={`intro-home`}>
                    <div data-scroll data-scroll-speed="-2" className={`text-[38px] lg:text-[160px] !leading-none relative z-10`}>Agents Of<br/>Harmony</div>

                    <div className="image">
                        <StaticImage
                            src={`${filepath}Hero/910726391052_ECCOEPOTBAGSLINGMONOGRAM_A.png`}
                            objectFit={`cover`} alt={``}
                            className={`w-full lg:w-9/12 relative z-0 -mt-10 lg:-mt-40`}
                        />
                    </div>

                    {/*
                    <div>
                        <SectionsMenu
                            options={[
                                {text: 'Harmony', anchor: ''},
                                {text: 'New Utopians', anchor: ''},
                                {text: 'Growth', anchor: ''},
                            ]}
                        />
                    </div>
*/}
                </section>

                <div className={`w-full text-center`} id={`intro-text`}>
                    <div data-scroll data-scroll-speed="1">
                        <div className={`px-8 lg:px-0 w-full lg:w-10/12 text-[24px] lg:text-[50px] !leading-tight mx-auto pt-14 pb-16 lg:pt-52 lg:pb-52`}>
                            Eclectic elements combine for a new way
                            forward. Our 3rd season creates a
                            harmonious balance between softness
                            and structure, functionality and emotion
                            to develop a blueprint for the future.
                        </div>
                    </div>
                </div>

                <div className={`!bg-[#DB328B] w-full`}>
                    <Marquee
                        gradient={false}
                        speed={50}
                    >
                        <div className={`text-[#D0BDDD] text-[28px] lg:text-[60px] leading-none lg:leading-tight flex items-center pt-3 lg:pt-2 pb-4 lg:pb-4 whitespace-nowrap`}>
                            <div>&nbsp;ECCO Leather Goods – AW22&nbsp;</div>
                            {/*<Hand className={`w-[25px] lg:w-[38px] mx-2 lg:mx-4`}/>*/}
                            <div className={`w-[25px] lg:w-[38px] mx-2 lg:mx-4 text-center`}>⋅</div>
                            <div>&nbsp;ECCO Leather Goods – AW22</div>
                        </div>
                    </Marquee>
                </div>

                <div>
                    <InlineVideo src={`https://eccocpeu.blob.core.windows.net/video/elu-aw22/Ecco-AW22-Campaign-Sizzle-05.mp4`} skipBase={true} className={`w-full`}/>
                </div>

                {/* Section 01 */}

                <div className={`w-full xpb-20 lg:pb-12 text-center`} id={`section-01`}>
                    <div id={`slider-00`} className={`pt-4 lg:pt-12`}>
                        <VideoSlider
                            arrowColor="#5C6552"
                            arrowBgColor="#D9E861"
                            bgColor="#5C6552"
                            videos={sliderVideos}
                            videosThumb={sliderVideosThumb}
                            heading="Watch The Collection<br/>Presentations"
                            info={sliderVideosTexts}
                        />
                    </div>

                    <div data-scroll data-scroll-id="section-01" className={`w-full relative pt-10 lg:pt-20 pb-20 lg:pb-12 text-center`}>
                        <TitleH1 title={`Seamless<br/>Wearability`} subtitle={`Connected with modern life, this season’s bags are designed to move with<br/> you, whilst traveling, commuting, and from day
                            to night.`}/>

                        <Grid className={`w-full`}>
                            <div data-scroll data-scroll-speed="2" className={`col-start-2 col-end-13 lg:col-start-3 lg:col-end-13 z-20`}>
                                <StaticImage src={`${filepath}section-01/ECCOSAILBAGCOMPACT_A1.png`} alt={``}/>
                            </div>

                            <div data-scroll data-scroll-speed="3" className={`col-start-2 col-end-10 lg:col-start-2 lg:col-end-8 relative z-20 mt-8`}>
                                <StaticImage src={`${filepath}section-01/ECCOSAILBAGCOMPACT_B.png`} alt={``}/>
                            </div>

                        </Grid>
                    </div>

                    <div className={`w-full mt-[-20vh] lg:mt-[-50vh]`}>
                        <StaticImage src={`${filepath}section-01/pattern_01_extended.png`} objectFit={`cover`} alt={``} className={`w-full h-full`}/>
                    </div>

                    <Grid className={`w-full color-block bg-[#E83D26]`}>
                        <div data-scroll data-scroll-speed="5" className={`col-start-2 col-end-13 lg:col-start-3 lg:col-end-6 relative z-20 mt-5 lg:mt-[10vh]`}>
                            <TitleH2 title={'Designed For<br/> Less Waste'} subtitle={'The ECCO Sail Bag in DriTan Leather <br/>with minimal hardware.'}/>
                        </div>

                        <div data-scroll data-scroll-speed="2" className={`col-start-3 col-end-12 lg:col-start-6 lg:col-end-10 relative z-20 mt-0 lg:-mt-48`}>
                            <StaticImage src={`${filepath}section-01/910723891033_ECCOSAILBAGFULLSIZE_A.png`} alt={``}/>
                        </div>

                        <div data-scroll data-scroll-speed="1" className={`col-start-3 col-end-13 lg:col-start-9 lg:col-end-13 relative z-20 mt-5  lg:mt-[-10vh]`}>
                            <TitleH2 title={'Vibrant<br/>Scarlet'} subtitle={' A fresh take on autumnal colours<br/>with energetic brights.'}/>
                        </div>
                    </Grid>

                    <Grid className={`w-full color-block bg-[#4F0D47]`}>
                        <div data-scroll data-scroll-speed="2" className={`col-start-2 col-end-12 lg:col-start-2 lg:col-end-8 relative z-20 mt-10 lg:-mt-48`}>
                            <StaticImage src={`${filepath}section-01/ECCOWAVEPINCHBAG_A.png`} alt={``}/>
                        </div>
                    </Grid>

                    <div id={`slider-01`} className={`pb-5 lg:pb-0`}>
                        <ImageSliderEx arrowColor="#D0BDDD" arrowBgColor="#4F0D47" bgColor="#D0BDDD" images={slider01} heading="<br/>" info={slider01text}/>
                    </div>
                </div>

                {/* Section 02 */}

                <div className={`w-full pt-10 lg:pt-20 text-center`} id={`section-02`}>
                    <TitleH1 title={'Leather<br/>Comes Alive'}
                             subtitle={'From reflective liquid Shine to voluminous embossing and Ultra Soft pleats<br/> and drapes, leather comes alive with movement and tactility.'}/>

                    <Grid className={`w-full`}>
                        <div data-scroll data-scroll-speed="3" className={`col-start-1 col-end-12 lg:col-start-1 lg:col-end-10 relative z-20 pb-[20vh] lg:pb-0`}>
                            <StaticImage src={`${filepath}section-02/ECCOSCRUNCHHOBOCOMPACT_ECCOPOTBAGDOUBLE_ECCOPOT.png`} alt={``}/>
                        </div>
                    </Grid>

                    <Grid className={`w-full color-block bg-[#5f6454] text-[#D9E861]`}>
                        <div data-scroll data-scroll-speed="2" className={`col-start-2 col-end-10 lg:col-start-2 lg:col-end-6 mt-[-15vh] lg:mt-0`}>
                            <StaticImage src={`${filepath}section-02/910722991035_ECCOSCRUNCHHOBOCOMPACT_A.png`} alt={``}/>
                        </div>

                        <div data-scroll data-scroll-speed="3" className={`col-start-2 col-end-12 lg:col-start-3 lg:col-end-6`}>
                            <TitleH2 title={'Buttersoft Tactility'} subtitle={'The ECCO Scrunch Hobo introduces a new leather experience with ultra soft technology - this time in buttersoft, combined with a comforting organic shape.'}/>
                        </div>

                        <div data-scroll data-scroll-speed="5" className={`col-start-4 col-end-12 lg:col-start-8 lg:col-end-12 lg:mt-[-50vh] pb-[20vh] lg:pb-0`}>
                            <StaticImage src={`${filepath}section-02/910722591039_ECCOSCRUNCHHOBOCOMPACT_A.png`} alt={``}/>
                        </div>
                    </Grid>

                    <Grid className={`w-full color-block bg-[#181B41] text-[#D9E861]`}>
                        <div data-scroll data-scroll-speed="2" className={`col-start-2 col-end-12 lg:col-start-2 lg:col-end-8 mt-[-15vh] lg:mt-[-5vh]`}>
                            <StaticImage src={`${filepath}section-02/910722191035_910721591035_ECCOCERAMICBENTOPACK_ECCOJOURNEYPILLOWPACK_A.png`} alt={``}/>
                        </div>

                        <div data-scroll data-scroll-speed="5" className={`col-start-3 col-end-12 lg:col-start-2 lg:col-end-6 lg:mt-[10vh]`}>
                            <TitleH2 title={'Indigo Leather'} subtitle={'Inspired by denim and created with natural <br/> pigments to highlight the uniqueness of each skin.'}/>
                        </div>

                        <div data-scroll data-scroll-speed="4" className={`col-start-3 col-end-12 lg:col-start-7 lg:col-end-12 pb-10 lg:pb-0`}>
                            <StaticImage src={`${filepath}section-02/910723690189_910722890189_910720590189_ECCOINDIGOPINCHBAG_ECCOINDIGODOUBLEPOTBAG_ECCOINDIGOMIDIPOUCH_A.png`} alt={``}/>
                        </div>
                    </Grid>

                </div>

                {/* Section 03 */}

                <div className={`w-full pt-10 lg:pt-20 text-center`} id={`section-03`}>
                    <TitleH1 title={'Meet The<br/>New Arrivals'}
                             subtitle={'The iconic Pot Bag in new colours and sizes <br/> to combine.'}/>

                    <Grid className={`w-full`}>
                        <div data-scroll data-scroll-speed="3" className={`col-start-2 col-end-13 lg:col-start-3 lg:col-end-13 relative z-20 lg:mt-[-10vh] pb-[20vh]`}>
                            <StaticImage src={`${filepath}section-02/ECCOTEXTUREBLOCKMIDIPOUCH_A.png`} alt={``}/>
                        </div>
                    </Grid>

                    <Grid className={`w-full color-block bg-[#D0BDDD]`}>
                        <div data-scroll data-scroll-speed="3" className={`col-start-2 col-end-9 lg:col-start-2 lg:col-end-7 mt-[-15vh] lg:mt-[-15vh]`}>
                            <StaticImage src={`${filepath}section-02/910582691071_910594791071_ECCOTEXTUREBLOCKPOTBAG_ECCOTEXTUREBLOCKPOTBAGMINI_A.png`} alt={``}/>
                        </div>
                    </Grid>

                    <Grid className={`w-full color-block bg-[#181B41] text-[#E83D26]`}>
                        <div data-scroll data-scroll-speed="2" className={`col-start-3 col-end-12 lg:col-start-7 lg:col-end-11 mt-[-30vh] lg:mt-[10vh]`}>
                            <StaticImage src={`${filepath}section-02/910722791038_910722791053_910722890189_ECCOPOTBAGDOUBLE_ECCOPOTBAGDOUBLE_ECCOPOTBAGDOUBLEINDIGO_A.png`} alt={``}/>
                        </div>

                        <div data-scroll data-scroll-speed="2" className={`col-start-2 col-end-12 lg:col-start-7 lg:col-end-10`}>
                            <TitleH2 title={'The Pot Gets <br/> Playful'}
                                     subtitle={'The ECCO Scrunch Hobo introduces a new leather experience with ultra soft technology - this time in buttersoft, combined with a comforting organic shape.'}/>
                        </div>

                        <div data-scroll data-scroll-speed="4" className={`col-start-2 col-end-10 lg:col-start-2 lg:col-end-6 lg:mt-[-30vh]`}>
                            <StaticImage src={`${filepath}section-02/910722890189_ECCOPOTBAGDOUBLEINDIGO_A.png`} alt={``}/>
                        </div>

                    </Grid>

                    <div id={`slider-02`} className={`mt-[-5vh] lg:mt-0 pb-5 lg:pb-0`}>
                        <ImageSliderEx
                            arrowColor="#D9E861"
                            arrowBgColor="#181B41"
                            bgColor="#D9E861"
                            images={slider02}
                            heading="<br/>"
                            info={slider02text}
                        />
                    </div>
                </div>

                {/* Section 04 */}

                <div className={`w-full pt-10 lg:pt-20 text-center`} id={`section-04`}>
                    <Grid className={`w-full`}>
                        <div data-scroll data-scroll-speed="1" className={`col-start-3 col-end-11 lg:col-start-4 lg:col-end-10 my-10 lg:my-20`}>
                            <StaticImage src={`${filepath}section-03/E_Logo.png`} alt={``}/>
                        </div>
                    </Grid>

                    <TitleH1 title={'E Collection'}
                             subtitle={'Playful, youthful, colourful and essential. The ECCO E Collection brings <br/> depth, volume and excitement to everyday icons.'}/>

                    <Grid className={`w-full`}>
                        <div data-scroll data-scroll-speed="2" className={`col-start-2 col-end-12 lg:col-start-2 lg:col-end-12`}>
                            <StaticImage src={`${filepath}section-03/910726191050_ECCOEPHONEBAGSTACKDOUBLEE_A.png`} alt={``}/>
                        </div>

                        <div data-scroll data-scroll-speed="3" className={`col-start-4 col-end-12 lg:col-start-3 lg:col-end-7 lg:mt-[-2vh]`}>
                            <TitleH2 title={'Hyper Connectivity'} subtitle={' A Phone bag, mini Pot Bag and Alphabet Charm <br/> combine in the ECCO E Phone Bag Stack.'}/>
                        </div>

                        <div data-scroll data-scroll-speed="2" className={`col-start-2 col-end-12 lg:col-start-2 lg:col-end-10 lg:mt-[-5vh]`}>
                            <StaticImage src={`${filepath}section-03/910726291052_ECCOEPHONEBAGSTACKMONOGRAM_A.png`} alt={``}/>
                        </div>

                        <div data-scroll data-scroll-speed="6" className={`col-start-2 col-end-12 lg:col-start-10 lg:col-end-13 lg:pl-10 lg:mt-[40vh]`}>
                            <TitleH2 title={'3D Impact'} subtitle={'Leather comes alive with <br/>voluminous embossed <br/> monogramming.'}/>
                        </div>
                    </Grid>

                    <Grid className={`w-full color-block bg-[#D9E861] text-[#4F0D47]`}>
                        <div data-scroll data-scroll-speed="4" className={`col-start-2 col-end-10 lg:col-start-2 lg:col-end-6 lg:pl-10 lg:mt-[25vh]`}>
                            <TitleH2 title={'Bold & Refined'}
                                     subtitle={'The ECCO E Elongated Tote is raw and unlined to <br/> appreciate the beauty of the leather, features the <br/> iconic Double E Emblem, and has a genderless <br/> silhouett for all.'}/>
                        </div>
                        <div data-scroll className={`col-start-3 col-end-12 lg:col-start-6 lg:col-end-11 py-10 lg:py-20`}>
                            <StaticImage src={`${filepath}section-03/910723090912_ECCOETOTE_A.png`} alt={``}/>
                        </div>
                    </Grid>

                    <StaticImage
                        src={`${filepath}section-03/pattern_02.png`}
                        objectFit={`cover`} alt={``}
                        className={`w-full`}
                    />

                    <Grid className={`w-full bg-[#D0BDDD] text-[#4F0D47]`}>
                        <div data-scroll data-scroll-speed="2" className={`col-start-2 col-end-12 lg:col-start-2 lg:col-end-12 py-10 lg:py-0`}>
                            <StaticImage src={`${filepath}section-03/910726391052_ECCOEPOTBAGSLINGMONOGRAM_A.png`} alt={``}/>
                        </div>

                        <div data-scroll data-scroll-speed="4" className={`col-start-2 col-end-9 lg:col-start-3 lg:col-end-6`}>
                            <StaticImage src={`${filepath}section-03/910726591038_ECCOEPOTBAGSLINGDOUBLEE_A.png`} alt={``}/>
                        </div>

                        <div data-scroll data-scroll-speed="2" className={`col-start-2 col-end-11 lg:col-start-7 lg:col-end-11 lg:mt-[10vh]`}>
                            <TitleH2 title={'In Harmony'}
                                     subtitle={'The draped and gathered leather of a new <br/> drawstring closure creates movement against the <br/> protective structure of the ECCO E Pot Bag Sling.'}/>
                        </div>
                    </Grid>

                    <div id={`slider-03`} className={`pb-5 lg:pb-0`}>
                        <ImageSliderEx
                            arrowColor="#D0BDDD"
                            arrowBgColor="#4F0D47"
                            bgColor="#D0BDDD"
                            images={slider03}
                            heading=""
                            info={slider03text}
                        />
                    </div>
                </div>

                {/*Index*/}

                <div className={`w-full pt-10 lg:pt-20 text-center bg-[#5C6552] text-[#D9E861]`}>
                    <Grid className={`w-full text-left pb-10 lg:pb-20`}>
                        <div className={'col-start-1 col-end-13 lg:col-start-1 lg:col-end-4 pl-8 mb-8'}>
                            <div className={`text-[24px] lg:text-[80px] mb-2 lg:mb-14 leading-none`}>—&nbsp;Index</div>
                        </div>

                        <div className={'col-start-1 col-end-13 lg:col-start-5 lg:col-end-7 px-8'}>
                            1. ECCO E Pot Bag Sling Monogram in Berry Sorbet/Ultra Violet
                            <br/><br/>
                            2. ECCO Sail bag in Pastel Lilac Ecco Textureblock Pot Bag in Pastel Lilac ECCO Mini Pot in Pastel Lilac ECCO Textureblock Saddle Bag in Pastel Lilac
                        </div>
                        <div className={'col-start-1 col-end-13 lg:col-start-7 lg:col-end-9 px-8'}>
                            3. ECCO Sail Bag Full Size in Vibrant Scarlet
                            <br/><br/>
                            4. ECCO Wave Pinch Bag and ECCO Textureblock Casette Bag Compact in Pastel Lilac/Granita
                            <br/><br/>
                            5. ECCO Scrunch Hobo Compact in Sunny Lime, Violet and First Tee
                            <br/><br/>
                            6. ECCO Scrunch Hobo Compact in Sunny Lime, Violet and First Tee
                            <br/><br/>
                            7. ECCO Scrunch Hobo Compact in Sunny Lime, Violet and First Tee
                            <br/><br/>
                            8. ECCO Journey Pillow Pack in Sunny Lime ECO Bento Pack in Sunny Lime
                        </div>
                        <div className={'col-start-1 col-end-13 lg:col-start-9 lg:col-end-11 px-8'}>
                            9. ECCO Indigo Casette Bag compact ECCO Indigo Midi Pouch ECCO Indigo Pot Bag Double
                            <br/><br/>
                            10. ECCO Pot Bag, ECCO Pot Bag Double and ECCO Pot Bag Minis in assorted colours.
                            <br/><br/>
                            11. ECCO Pot Bag and ECCO Pot Bag Mini in Pastel Lilac
                            <br/><br/>
                            12. ECCO Pot Bag Double in First Tee, Indigo and NIght Ocean/Cloud
                            <br/><br/>
                            13. ECCO Pot Bag Double in Indigo
                        </div>

                        <div className={'col-start-1 col-end-13 lg:col-start-11 lg:col-end-13 px-8'}>
                            14. ECCO E Phone Stack in First Tee
                            <br/><br/>
                            15. ECCO E Phone Stack in Berry Sorbet/Violet
                            <br/><br/>
                            16. ECCO E Tote in Wet Almond
                            <br/><br/>
                            17. ECCO E Pot Bag Sling Monogram in Berry Sorbet/Ultra Violet
                            <br/><br/>
                            18. ECCO E Pot Bag Sling Double E in First Tee
                        </div>
                    </Grid>
                </div>

                {/*Conclusion*/}

                <div className={`w-full pt-10 lg:pt-20 text-center bg-[#D0BDDD] text-[#DB328B]`}>
                    <Grid className={`w-full text-left pb4 lg:pb-20`}>
                        <div className={'col-start-1 col-end-13 lg:col-start-1 lg:col-end-5 pl-4 lg:pl-8'}>
                            <div className={`text-[24px] lg:text-[50px] leading-tight lg:leading-none mb-8 mt-8 px-4`}>Autumn/Winter<br/>2022 Collection<br/>Agents Of Harmony</div>
                        </div>


                        <div className={'col-start-1 col-end-13 lg:col-start-7 lg:col-end-10 px-8'}>
                            <p>
                                This collection capture the special harmony that happens when eclectic elements combine. Both out bags, and New Utopians, are Agents Of Harmony - looking for new ways to make the different parts of our life
                                work
                                together in synchronicity.
                                <br/><br/>
                                Movement is an integral part of the concept. Leather comes alive with new 3 Dimensional varieties that focus on comfort and pleasure, building the story of hands-free movement and ease.
                                <br/><br/>
                                The next generation of leather goods should be worn everywhere, combining functionality with pleasure - and bringing harmony to out hybrid lives. Youthful and multifunctional pieces are designed to fit
                                seamlessly
                                into the lives of New Utopians.
                            </p>
                        </div>

                        <div className={'col-start-1 col-end-13 lg:col-start-10 lg:col-end-13 px-8'}>
                            At work or play, harmony is created with the combination of yin yang elements such as structure and softness. "This season we designed bags like shoes - with comfort and movement as the priority."
                            <br/><br/>
                            Eric Jandar, Lead Designer Accessories.
                        </div>
                    </Grid>

                    <div className={`px-8 text-left pb-10 lg:pb-20`}>
                        Feel free to download
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 24}}>
                            <a
                                href={`https://eccocpeu.blob.core.windows.net/video/elu-aw22/ECCO Leather Goods_AW22_Collection_press release.pdf`}
                                target={`_blank`}
                                className="cursor-pointer flex flex-nowrap items-center space-x-2"
                                style={{border: '1px solid #DB328B', borderRadius: 24, padding: '6px 12px', marginRight: 24}}
                            >
                                <p className="whitespace-nowrap">{'Press Release'}</p>
                            </a>
                            <a
                                href={`https://eccocpeu.blob.core.windows.net/video/elu-aw22/ECCO Leather Goods_AW22_Collection_lookbook.pdf`}
                                target={`_blank`}
                                className="cursor-pointer flex flex-nowrap items-center space-x-2"
                                style={{border: '1px solid #DB328B', borderRadius: 24, padding: '6px 12px'}}
                            >
                                <p className="whitespace-nowrap">{'Lookbook'}</p>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default AW22Landing
